import React from "react";
import styles from "./footer.module.css";
import { FaFacebook, FaInstagram } from "react-icons/fa";

export default function Footer(props) {
  return (
    <div style={{ width: "100%", float: "left", position: "relative", top: "100px" }}>
      <footer className={styles.footerContainer}>
        <div className={styles.socialContainer}>
          <a href="https://www.instagram.com/skinsolutionsaesthetics/" className={styles.socialLinks}>
            <FaInstagram />
          </a>
          <a href="https://www.facebook.com/skinsolutions/" className={styles.socialLinks}>
            <FaFacebook />
          </a>
        </div>
        <div className={styles.copyRightLabel}>© 2020 by SKIN Solutions Aesthetics.</div>
        <div className={styles.legalContainer}>
          <a className={styles.legalLink} href='/servicesbrochure.pdf' target="_blank">Services Brochure</a>
          <a className={styles.legalLink} href='/termsofuse.pdf' target="_blank">Terms of Use</a>
          <a className={styles.legalLink} href='/privacypolicy.pdf' target="_blank">Privacy Policy</a>
        </div>
      </footer>
    </div>
  );
}
